<template>
  <div class="content-wrapper">
    <div class="customer main-content">
      <div class="page-header">
        <h1>Potential Customer Information</h1>
        <PotentialCustomerForm :viewMode="false" :eventName="'addCustomer'" @addCustomer="addCustomer"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import PotentialCustomerForm from '@/components/sales/PotentialCustomerForm.vue';
import Common from '@/lib/common';
import Hr from '@/lib/hr';
import Sales from '@/lib/sales';

export default {
  name: 'AddCustomer',
  components: {
    PotentialCustomerForm,
  },
  methods:{
    async getEmployeeByName(memberName){
      const loginInfo = Common.getLoginInfo();
      const searchFields = {
        'employee_name': memberName,
      };
      const employees = await Hr.searchEmployee(this.apiUrl, searchFields, loginInfo);
      const employee = employees[0];
      return employee;
    },
    async addCustomer(form){
      try{
        const employee = await this.getEmployeeByName(form.principalEmployeeId);
        if(employee){
          const employeeId = employee.id;
          form.principalEmployeeId = employeeId;
        }else{
          form.principalEmployeeId = null;
        }
        const loginInfo = Common.getLoginInfo();
        const customer = await Sales.createPotentialCustomer(this.apiUrl, form, loginInfo);
        this.$router.push('/sales/pontential-customers');
      }catch(err){
        console.log(err);
      }
    },
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.page-header{
  h1{
    margin-bottom: 50px;
  }
}
</style>
